@import "./variables";

@keyframes pRotate {
    0% {

        stroke-dashoffset: -308;
    }
    50% {
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dashoffset: 308;
    }
}
@keyframes pRotate2 {
    0% {

        stroke-dashoffset: -596;
    }
    50% {
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dashoffset: 596;
    }
}

svg.logoSvg #loader-logo-y {
    animation-name: pRotate;
    animation-duration: 2s;
    animation-delay:-0.5s;
    animation-iteration-count: infinite;
    animation-direction:reverse;
    animation-timing-function: linear;
    stroke-dasharray: 308;
    stroke: #f39200;
    stroke-width:2;
    stroke-linejoin:round;
    stroke-linecap:round;
}

svg.logoSvg #loader-logo-7 {
    animation-name: pRotate2;
    animation-duration:3s;
    /*animation-delay:0.5s;*/
    animation-iteration-count: infinite;
    animation-direction:reverse;
    animation-timing-function: linear;
    stroke-dasharray: 596;
    stroke: #f39200;
    stroke-width:2;
    stroke-linejoin:round;
    stroke-linecap:round;
}


#pageLoader {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:$black-gradient;
    color:$color-white;
    display:flex;
    flex-direction:column;
    text-align:center;
    align-items:center;
    justify-content:center;
    opacity:1;
    transition:all .75s ease;
    //animation: .75s ease 0s 1 slideInTop;
    z-index:99;


    &.finished {
        opacity:0;
        transform:scale(5);
        //animation: .75s ease 0s slideOutTop;
        pointer-events:none;
        //transform: translateY(-100%);
    }

    &.loading {
        transform:scale(1);
        opacity:1;
        pointer-events:all;
        //animation: .75s ease 0s slideInTop;
    }

    svg path {
        fill:url(#whitegradient);
    }
}

@keyframes slideInTop {
    0% {
        //transform:translateY(-100%);
        opacity:0;
        transform:scale(5);
    }
    100% {
        //transform:translateY(0);
        opacity:1;
        transform:scale(1);
    }
}
@keyframes slideOutTop {
    0% {
        //transform: translateY(0);
        opacity:1;
        transform:scale(1);
    }
    100% {
        //transform: translateY(-100%);
        opacity:0;
        transform:scale(5);
    }
}