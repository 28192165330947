#App {
  background: linear-gradient(to right, #1d1d1d, #000);
  position: relative; }

.is-color-black {
  color: #000; }

.bgVideo-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(135deg, #878787 0%, #222 100%); }
  .bgVideo-container.darken::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); }

.bgVideo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%; }

#content {
  color: #f1f1f1;
  /*
  background:#000;
  width: 100%;
  height:100%;
  */
  position: relative;
  z-index: 90;
  pointer-events: none;
  min-height: 100vh; }
  #content.behind-footer {
    z-index: 79; }
  #content .inner {
    pointer-events: all;
    position: relative;
    min-height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 78px;
    padding-bottom: 30px;
    min-height: calc(100vh + 1px);
    overflow: hidden; }
    #content .inner.sectioned {
      padding-top: 68px;
      padding-bottom: 0; }
      #content .inner.sectioned section {
        padding: 5rem 0;
        min-height: calc(100vh - 68px);
        position: relative;
        overflow: hidden; }
        #content .inner.sectioned section .corner {
          position: absolute;
          top: -1px;
          bottom: auto; }
          #content .inner.sectioned section .corner.right {
            right: -1px;
            height: 100%;
            width: 60vh; }
            @media (max-width: 1023px) {
              #content .inner.sectioned section .corner.right {
                visibility: hidden; } }
          #content .inner.sectioned section .corner.bottom {
            display: block;
            right: -1px;
            bottom: -1px;
            height: auto;
            width: 60vh;
            transform: scaleY(-1); }
            @media (max-width: 1023px) {
              #content .inner.sectioned section .corner.bottom {
                width: 40vw; } }
            @media (max-width: 768px) {
              #content .inner.sectioned section .corner.bottom {
                visibility: hidden; } }
          #content .inner.sectioned section .corner.bottom2 {
            display: block;
            right: -1px;
            bottom: -1px;
            height: auto;
            width: 45vh;
            transform: scaleY(-1); }
            @media (max-width: 1023px) {
              #content .inner.sectioned section .corner.bottom2 {
                width: 30vw; } }
            @media (max-width: 768px) {
              #content .inner.sectioned section .corner.bottom2 {
                visibility: hidden; } }
        #content .inner.sectioned section .sectionDescription {
          margin: 3rem 0; }
          @media (max-width: 768px) {
            #content .inner.sectioned section .sectionDescription {
              margin: 2rem 0; } }
        #content .inner.sectioned section.dark {
          /*background:$color-dark;*/
          background: linear-gradient(135deg, #878787 0%, #222 100%); }
          #content .inner.sectioned section.dark h2 {
            color: #f39200; }
        #content .inner.sectioned section.light {
          background: linear-gradient(135deg, #ffffff, #DDD, #AAA);
          color: #222; }
          #content .inner.sectioned section.light h2 {
            color: #f39200; }
        #content .inner.sectioned section.orange {
          background: linear-gradient(135deg, #ffb45a, #f39200, #db6300);
          color: #222; }
          #content .inner.sectioned section.orange h2 {
            color: #FFF; }
          #content .inner.sectioned section.orange .button::before {
            background-image: url("../images/buttons/buttonBefore_inverted.svg"); }
        #content .inner.sectioned section.no-background {
          background: transparent; }
      #content .inner.sectioned > section#webservice > .container {
        overflow: visible; }
      #content .inner.sectioned > section#webservice .imgcol img {
        transform: translate(-20px, -50px); }
      @media (max-width: 1023px) {
        #content .inner.sectioned > section#webservice .imgcol img {
          transform: translate(0px, 0px); } }
    #content .inner.va-center {
      align-items: center;
      justify-content: center; }
  #content > div {
    width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    opacity: 0;
    animation: fadeInContent 0.75s linear 0s 1 forwards; }
    #content > div.fa-exit {
      opacity: 1;
      animation: none;
      position: absolute !important; }

@keyframes fadeInContent {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.container {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden; }
  .container.orangebg {
    background: linear-gradient(135deg, #f39200, #f57800); }
  .container:not(.fluid) {
    padding-left: 30px;
    padding-right: 30px; }
  .container.noTitleMargin {
    padding-top: 60px; }
  @media (min-width: 1024px) {
    .container {
      padding-left: 0;
      padding-right: 0; } }
  .container.flex-grow {
    display: flex;
    flex-grow: 1;
    justify-items: stretch;
    overflow: visible; }
    .container.flex-grow > div {
      flex-grow: 1; }
  .container.fluid {
    max-width: none;
    width: 100%; }
    .container.fluid > .row {
      margin-left: 0;
      margin-right: 0; }

.row {
  position: relative;
  margin-left: -30px;
  margin-right: -30px;
  display: flex;
  flex-direction: column; }
  @media (min-width: 1024px) {
    .row {
      flex-direction: row; } }

.col {
  padding-left: 30px;
  padding-right: 30px;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1; }
  .col.valign {
    display: block; }
    @media (min-width: 1024px) {
      .col.valign {
        display: flex; } }
    .col.valign.imgcol {
      text-align: center; }
    .col.valign > div {
      align-self: center;
      width: 100%; }
      .col.valign > div > img {
        width: 100%;
        max-width: 640px;
        height: 100%; }
  .col + .col {
    margin-top: 30px; }
  @media (min-width: 1024px) {
    .col + .col {
      margin-top: 0; } }

.col-sidebar {
  width: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  /*border-left:1px solid #FFF;*/ }
  .col-sidebar .col-sidebar-fixed {
    position: fixed; }
  .col-sidebar .sidebar-box {
    margin-top: 60px; }

/* TYPO */
.header-row {
  overflow: hidden; }
  .header-row > .col {
    display: flex;
    width: 100%;
    height: 148px;
    justify-content: center;
    align-items: center;
    flex-basis: 1; }
    .header-row > .col .content h1 {
      margin: 20px 0 0 0; }

.content {
  font-size: 1.125rem; }
  .content p {
    margin-bottom: 20px; }
    .content p + h2 {
      margin-top: 60px; }
  .content h1 {
    font-size: 3.5rem;
    margin: 60px 0 40px;
    text-align: center;
    font-family: 'Rajdhani';
    font-weight: 700; }
    @media (max-width: 768px) {
      .content h1 {
        font-size: 2.5rem; } }
  .content h2 {
    font-size: 3.25rem;
    margin: 0 0 40px 0;
    font-family: 'Rajdhani';
    font-weight: 700; }
    .content h2 + h3 {
      margin-top: -40px; }
    @media (max-width: 768px) {
      .content h2 {
        font-size: 2rem; } }
  .content h3 {
    font-size: 2rem;
    font-weight: 300; }
    @media (max-width: 768px) {
      .content h3 {
        font-size: 1.5rem; } }
  .content a {
    color: #f39200;
    text-decoration: none;
    cursor: pointer; }
    .content a:not(.button):hover {
      background: linear-gradient(135deg, #f39200, #f57800);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text; }
  .content strong {
    font-weight: 600; }
  .content ul, .content ol {
    padding-left: 30px; }
    .content ul li, .content ol li {
      position: relative;
      margin-top: 5px;
      margin-bottom: 5px; }
      .content ul li::before, .content ol li::before {
        content: '';
        position: absolute;
        left: calc(-30px / 2);
        top: 2px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 10px;
        border-color: transparent transparent transparent #f39200; }

.arrow-indicator {
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  animation: bounceY 1s ease-in-out infinite; }

@keyframes bounceY {
  0%, 100% {
    transform: translate(0, 0); }
  50% {
    transform: translate(0, -2px); } }
