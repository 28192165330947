/*
#navLink_home > a {
    filter:drop-shadow(0 0 5px rgba(0,0,0,.35));
}
*/
#mainNav {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
  max-width: 100vw;
  background: linear-gradient(to top, rgba(28, 133, 211, 0.9), rgba(48, 119, 201, 0.9));
  box-shadow: 0 5px 0 0 rgba(0, 0, 0, 0.45);
  transition: all .5s ease;
  transition: width 0s; }
  #mainNav.is-mobile {
    transition: 0.2s ease-in-out;
    transition-delay: 0.2s;
    height: 62px; }
    #mainNav.is-mobile ul {
      position: absolute;
      top: 0;
      left: 0;
      background: #1c85d3;
      opacity: 0;
      transition: 0.3s ease-in-out;
      transition-delay: 0s; }
    #mainNav.is-mobile.nightmode ul {
      background: linear-gradient(to top, #222222, black); }
    #mainNav.is-mobile .mobile-page-title-container {
      transform: translateY(0px);
      transition: 0.5s ease-in-out;
      transition-delay: 0.3s; }
    #mainNav.is-mobile .homelink {
      transition: 0.2s ease-in-out;
      transition-delay: 0.4s;
      transform: translateX(0px); }
    #mainNav.is-mobile #mobileNavTrigger {
      z-index: 104;
      position: static;
      justify-content: flex-start; }
      #mainNav.is-mobile #mobileNavTrigger::before {
        content: '';
        position: absolute;
        height: 60px;
        width: 60px;
        right: 0;
        top: 0; }
      #mainNav.is-mobile #mobileNavTrigger svg {
        position: absolute;
        right: 20px;
        top: 15px; }
    #mainNav.is-mobile .page-title {
      font-weight: bold;
      justify-content: flex-start; }
    #mainNav.is-mobile.is-active {
      height: 236px;
      transition-delay: 0s; }
      #mainNav.is-mobile.is-active .mobile-page-title-container {
        align-items: flex-start; }
      #mainNav.is-mobile.is-active ul {
        z-index: 103;
        opacity: 1;
        transition-delay: 0.2s; }
      #mainNav.is-mobile.is-active .mobile-page-title-container {
        transition-delay: 0s;
        transform: translateY(-200px); }
      #mainNav.is-mobile.is-active .homelink {
        transform: translateX(-200px);
        transition-delay: 0.2s; }
      #mainNav.is-mobile.is-active #mobileNavTrigger {
        align-items: flex-start; }
  #mainNav.homepage {
    transition: 0.2s ease-in-out;
    background: linear-gradient(to top, rgba(28, 133, 211, 0), rgba(48, 119, 201, 0));
    box-shadow: none; }
    #mainNav.homepage.is-mobile ul {
      transition: 0.4s linear;
      top: -410%;
      transition-delay: 0.2s; }
      #mainNav.homepage.is-mobile ul li {
        opacity: 0;
        transition: 0.2s ease-in-out; }
    #mainNav.homepage.is-mobile.is-active ul {
      box-shadow: 0 5px 0 0 rgba(0, 0, 0, 0.45);
      top: 0;
      transition-delay: 0s; }
      #mainNav.homepage.is-mobile.is-active ul li {
        opacity: 1;
        transition-delay: 0.5s; }
    #mainNav.homepage > ul > li#navLink_home,
    #mainNav.homepage .homelink {
      opacity: 0;
      pointer-events: none;
      left: -100%; }
      #mainNav.homepage > ul > li#navLink_home a,
      #mainNav.homepage .homelink a {
        top: -5px; }
    #mainNav.homepage > ul > li:not(#navLink_home) a {
      background: transparent; }
    #mainNav.homepage > ul > li.main-item .submenu {
      background: transparent;
      border-top: 5px solid rgba(0, 0, 0, 0); }
    @media (max-width: 1023px) {
      #mainNav.homepage > ul {
        justify-content: center; } }
    #mainNav.homepage.nightmode {
      background: linear-gradient(to top, rgba(34, 34, 34, 0), rgba(0, 0, 0, 0)); }
      #mainNav.homepage.nightmode a:hover > span {
        color: #f39200; }
  #mainNav.nightmode {
    background: linear-gradient(to top, rgba(34, 34, 34, 0.9), rgba(0, 0, 0, 0.9)); }
    #mainNav.nightmode > ul > li a.active > span,
    #mainNav.nightmode > ul > li a:hover > span {
      color: #f39200; }
    #mainNav.nightmode:not(.homepage) > ul > li.main-item .submenu {
      background: #222; }
  @media (min-width: 1024px) {
    #mainNav:not(.homepage) > ul ul.submenu {
      box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.45); } }
  @media (max-width: 768px) {
    #mainNav {
      min-height: 55px;
      padding-top: 20px;
      padding-bottom: 14px;
      padding-left: 60px;
      display: flex;
      justify-content: space-between; }
      #mainNav > a {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0; }
        #mainNav > a #logoCloud {
          position: absolute;
          top: 5px;
          left: -20px;
          max-width: 180px; }
        #mainNav > a .logoSvg {
          position: absolute;
          top: 35px;
          left: 15px;
          z-index: 102; }
      #mainNav .mobile-page-title-container {
        margin: 0 auto; } }
  @media (max-width: 480px) {
    #mainNav .mobile-page-title-container .page-title {
      font-size: 1.2rem; } }
  #mainNav #logoCloud {
    filter: drop-shadow(5px 5px 0 rgba(0, 0, 0, 0.35));
    z-index: 101; }
  #mainNav #mobileNavTrigger {
    position: relative;
    display: inline-flex;
    align-items: center;
    color: #FFF;
    cursor: pointer; }
    #mainNav #mobileNavTrigger > span {
      margin-right: 10px; }
  #mainNav.initialized > ul > li {
    opacity: 1; }
    #mainNav.initialized > ul > li:not(#navLink_home) > a {
      top: 0; }
  #mainNav > ul {
    position: relative;
    z-index: 101;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
    list-style: none;
    padding: 0; }
    @media (max-width: 1023px) {
      #mainNav > ul {
        justify-content: flex-end; } }
    @media (max-width: 768px) {
      #mainNav > ul {
        display: block;
        pointer-events: none;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        width: 100%;
        left: 0;
        top: calc(100% + 4px);
        background: rgba(28, 133, 211, 0.95);
        opacity: 0;
        transition: opacity .2s ease;
        max-height: calc(100vh - 100% - 4px); }
        #mainNav > ul.active {
          pointer-events: all; } }
    #mainNav > ul > li {
      text-align: center;
      position: relative;
      opacity: 0;
      justify-content: center;
      flex-direction: column;
      z-index: 102; }
      @media (min-width: 769px) {
        #mainNav > ul > li {
          display: flex; } }
      @media (min-width: 1024px) {
        #mainNav > ul > li:not(#navLink_home) {
          margin: 0 10px; } }
      #mainNav > ul > li:not(#navLink_home) > a {
        position: relative;
        top: -65px;
        transition: top .5s ease; }
      #mainNav > ul > li#navLink_home {
        width: 220px;
        position: absolute;
        left: 0;
        opacity: 1;
        pointer-events: all;
        transition: opacity .5s ease;
        z-index: 103; }
        #mainNav > ul > li#navLink_home .logoSvg {
          position: absolute;
          z-index: 102;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -35%);
          width: 58%; }
        #mainNav > ul > li#navLink_home #logoCloud {
          position: relative; }
      @media (max-width: 768px) {
        #mainNav > ul > li {
          opacity: 1;
          flex-grow: 0; } }
      #mainNav > ul > li a {
        text-decoration: none;
        display: block;
        font-size: 24px;
        padding: 20px 5px 15px 5px;
        margin: 0 20px;
        position: relative;
        z-index: 10;
        font-family: 'Rajdhani';
        font-weight: 600;
        cursor: pointer;
        z-index: 102; }
        #mainNav > ul > li a:not(.homelink) {
          padding-left: 15px;
          padding-right: 15px;
          margin: 0 0; }
        #mainNav > ul > li a.homelink {
          padding: 0;
          top: -5px;
          left: 0;
          transition: all 0.5s ease; }
        #mainNav > ul > li a > span {
          position: relative;
          color: #FFF;
          display: inline-block;
          /*
                    &::after {
                        content:'';
                        background:$orange-gradient;
                        height:1px;
                        position:absolute;
                        width:0;
                        bottom:-5px;
                        left:50%;
                        margin-left:0;
                        opacity:0;
                        transition:all .5s ease;
                    }
                    */ }
          @media (min-width: 769px) {
            #mainNav > ul > li a > span > span {
              display: block; } }
          @media (min-width: 1216px) {
            #mainNav > ul > li a > span > span {
              display: inline-block; } }
          @media (max-width: 864px) and (min-width: 770px) {
            #mainNav > ul > li a > span > span {
              font-size: 1.1rem; } }
          #mainNav > ul > li a > span span.icon {
            margin-right: 10px; }
            #mainNav > ul > li a > span span.icon svg path {
              fill: url(#whitegradient); }
        #mainNav > ul > li a:hover > span,
        #mainNav > ul > li a.active > span {
          color: #000;
          /*
                    &::after {
                        width:60%;
                        margin-left:calc(-60% / 2);
                        opacity:1;
                    }
                    */ }
        #mainNav > ul > li a:hover span.icon svg path, #mainNav > ul > li a.active span.icon svg path {
          fill: url(#orangegradient); }
      #mainNav > ul > li.main-item .submenu-expander {
        position: absolute;
        z-index: 102;
        cursor: pointer;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        align-self: center;
        color: #fff; }
      #mainNav > ul > li.main-item .submenu {
        /*padding:0 15px 0 15px;*/
        width: 100%;
        position: absolute;
        z-index: 101;
        align-self: center;
        top: 0;
        background: #1c85d3;
        opacity: 0;
        pointer-events: none;
        transition: 0.2s ease-in-out;
        border-top: 5px solid rgba(0, 0, 0, 0.5); }
        #mainNav > ul > li.main-item .submenu a {
          font-weight: 500;
          font-size: 18px;
          text-align: left;
          padding: 10px 15px; }
      @media (min-width: 769px) {
        #mainNav > ul > li.main-item:hover .submenu {
          top: 100%;
          opacity: 1;
          pointer-events: all; } }
      @media (max-width: 768px) {
        #mainNav > ul > li.main-item .submenu-expander {
          padding: 15px;
          align-self: top;
          top: 10px;
          left: auto;
          right: 0;
          width: 50px;
          transform: none; }
        #mainNav > ul > li.main-item .submenu {
          background: rgba(0, 0, 0, 0.5);
          width: 100%; }
          #mainNav > ul > li.main-item .submenu.is-active {
            position: static;
            opacity: 1;
            pointer-events: all;
            background: rgba(0, 0, 0, 0.5); }
        #mainNav > ul > li.main-item > a {
          margin: 0; }
          #mainNav > ul > li.main-item > a.is-active {
            box-shadow: 0 5px 0 0 rgba(0, 0, 0, 0.5); } }

#mobileNavOutside {
  display: none;
  opacity: 0;
  background: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99;
  transition: opacity 0.2s ease; }
  #mobileNavOutside.active {
    display: block;
    opacity: 1; }

.mobile-page-title-container {
  display: inline-flex;
  align-items: center; }
  .mobile-page-title-container .page-title, .mobile-page-title-container .icon {
    font-size: 1.5rem; }
  .mobile-page-title-container .icon {
    margin-right: 10px; }
    .mobile-page-title-container .icon path {
      fill: url(#orangegradient); }
  .mobile-page-title-container .page-title {
    color: white; }

@keyframes erase {
  from {
    stroke-dashoffset: 0; }
  to {
    stroke-dashoffset: -2120; } }

@keyframes fill {
  to {
    fill-opacity: 1; } }

@keyframes unfill {
  from {
    fill-opacity: 1; }
  to {
    fill-opacity: 0; } }

#nav-icon {
  width: 25px;
  height: 22px;
  position: absolute;
  top: 20px;
  right: 20px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer; }

#nav-icon span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: white;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out; }

#nav-icon span:nth-child(1) {
  top: 0px; }

#nav-icon span:nth-child(2), #nav-icon span:nth-child(3) {
  top: 9px; }

#nav-icon span:nth-child(4) {
  top: 18px; }

#nav-icon.open span:nth-child(1) {
  top: 9px;
  width: 0%;
  left: 50%; }

#nav-icon.open span:nth-child(2) {
  transform: rotate(45deg); }

#nav-icon.open span:nth-child(3) {
  transform: rotate(-45deg); }

#nav-icon.open span:nth-child(4) {
  top: 9px;
  width: 0%;
  left: 50%; }
