/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

html {
  font-size: 18px;
  font-weight: 500; }
  @media (max-width: 768px) {
    html {
      font-size: 14px; } }

body,
input, textarea, select, button {
  font-family: "Rajdhani", sans-serif; }

body {
  background: #000; }

.section {
  padding: 80px 0; }

.inner.fullpage {
  min-height: 100vh; }

.logo-svg path, .logo-svg polygon {
  fill: url(#whitegradient); }

.logo-svg path#y7Dev-dev {
  fill: url(#bluegradient); }

.logo-svg.inAnimation path#y7Dev-y7, .logo-svg.inAnimation polygon#y7Dev-y7 {
  fill: rgba(255, 255, 255, 0);
  stroke: #FFF;
  stroke-width: 1;
  stroke-dasharray: 410;
  stroke-dashoffset: 410;
  animation: drawlogo_stroke 5s linear forwards, drawlogo_fill 2s linear 5s forwards; }

.logo-svg.inAnimation path#y7Dev-dev, .logo-svg.inAnimation polygon#y7Dev-dev {
  opacity: 0;
  animation: slideIn 2s ease 5s forwards; }

@keyframes drawlogo_stroke {
  to {
    stroke-dashoffset: 0; } }

@keyframes drawlogo_fill {
  to {
    fill: white; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-100%) skewX(-45deg); }
  to {
    opacity: 1;
    transform: translateX(0) skewX(0deg); } }

.button {
  font-size: 1.125rem;
  border-radius: 0;
  padding: 0px 14px;
  margin-bottom: 15px;
  display: inline-block;
  border: none;
  box-sizing: border-box;
  position: relative;
  z-index: 120;
  background: transparent;
  cursor: pointer; }
  .button > div {
    padding: 5px 15px 5px 20px;
    display: inline-flex;
    justify-content: center;
    flex-direction: row;
    text-decoration: none;
    box-sizing: border-box;
    background: #000;
    width: 100%;
    transition: 0.2s ease-in-out; }
    @media (max-width: 768px) {
      .button > div {
        padding: 5px 10px 5px 15px; } }
  .button.expanded {
    width: 100%;
    display: block; }
  .button::before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 15px;
    left: 0;
    top: 0;
    background-image: url("../images/buttons/buttonBefore.svg");
    background-size: 100% 100%;
    transition: 0.2s ease-in-out; }
  .button.whiteArrow::before {
    background-image: url("../images/buttons/buttonBefore_inverted.svg"); }
  .button::after {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 15px;
    right: 0;
    top: 0;
    background-image: url("../images/buttons/buttonAfter.svg");
    background-size: 100% 100%;
    transition: 0.2s ease-in-out; }
  .button > div > span {
    min-height: 36px;
    line-height: 36px;
    position: relative;
    z-index: 122;
    color: #FFF;
    display: inline-flex;
    transition: 0.2s ease-in-out; }
    .button > div > span > svg {
      height: 36px; }
    .button > div > span:not(.icon) {
      font-size: 1.125rem; }
    .button > div > span.icon {
      margin-left: 20px; }
  .button:hover {
    /*
      background:none !important;
      -webkit-background-clip:unset !important;
      -webkit-text-fill-color:unset !important;
      background-clip:unset !important;
      */ }
    .button:hover > span {
      color: #FFF; }
    .button:hover .icon.bounceRight {
      animation: bounceRight .5s ease alternate-reverse infinite; }
    .button:hover::before {
      background-image: url("../images/buttons/buttonBeforeHover.svg"); }
    .button:hover::after {
      background-image: url("../images/buttons/buttonAfterHover.svg"); }
    .button:hover > div {
      background: #333; }
  .button.progressing {
    cursor: wait; }
    .button.progressing .icon {
      animation: spin 2s linear infinite !important; }
    .button.progressing::after {
      animation: blinkFade .5s ease alternate-reverse infinite; }
  .button.light::after {
    background: rgba(255, 255, 255, 0.75); }
  .button.light > span {
    color: #000; }
  .button.light:hover > span {
    color: #000; }

.multiSelectFilter {
  padding: 0 15px; }
  .multiSelectFilter::before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 15px;
    left: 0;
    top: 0;
    background-image: url("../images/buttons/buttonOutlinedBefore.svg");
    background-size: 100% 100%; }
  .multiSelectFilter::after {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 15px;
    right: 0;
    top: 0;
    background-image: url("../images/buttons/buttonOutlinedAfter.svg");
    background-size: 100% 100%; }
  .multiSelectFilter .arrowSelect__control {
    background: #f39200; }
  .multiSelectFilter .arrowSelect__value-container {
    height: 44px; }
  .multiSelectFilter .arrowSelect__menu {
    left: 0; }

.field-container {
  position: relative;
  margin-bottom: 1rem; }
  .field-container.shadow {
    box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.45); }
  .field-container textarea.shadow, .field-container input.shadow {
    box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.45);
    -webkit-appearance: none;
    -webkit-border-radius: 0px; }
  .field-container .contact-form-field {
    border-radius: 0;
    width: auto;
    padding: 0.5rem;
    font-size: 16px;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    transition: border-color .2s linear; }
    .field-container .contact-form-field.expanded {
      width: 100%;
      border: none; }
    .field-container .contact-form-field:focus {
      border-color: #FFF; }
  .field-container textarea.contact-form-field {
    min-height: 100px;
    resize: vertical;
    overflow-x: hidden;
    overflow-y: auto; }
  .field-container .invalid-icon {
    position: absolute;
    right: .75rem;
    top: 50%;
    transform: translateY(-50%); }
    .field-container .invalid-icon path {
      fill: red; }
  .field-container.custom-checkbox {
    position: relative;
    padding-right: 40px;
    padding-top: 5px;
    min-height: 26px; }
    .field-container.custom-checkbox input {
      position: absolute;
      opacity: 0; }
    .field-container.custom-checkbox input + label {
      cursor: pointer;
      padding-left: 35px;
      display: block; }
      .field-container.custom-checkbox input + label::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: #fff;
        width: 24px;
        height: 24px;
        box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.45); }
      .field-container.custom-checkbox input + label::after {
        left: 2px;
        top: 2px;
        width: 20px;
        height: 20px;
        position: absolute;
        display: inline-block;
        content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik0xNzMuODk4IDQzOS40MDRsLTE2Ni40LTE2Ni40Yy05Ljk5Ny05Ljk5Ny05Ljk5Ny0yNi4yMDYgMC0zNi4yMDRsMzYuMjAzLTM2LjIwNGM5Ljk5Ny05Ljk5OCAyNi4yMDctOS45OTggMzYuMjA0IDBMMTkyIDMxMi42OSA0MzIuMDk1IDcyLjU5NmM5Ljk5Ny05Ljk5NyAyNi4yMDctOS45OTcgMzYuMjA0IDBsMzYuMjAzIDM2LjIwNGM5Ljk5NyA5Ljk5NyA5Ljk5NyAyNi4yMDYgMCAzNi4yMDRsLTI5NC40IDI5NC40MDFjLTkuOTk4IDkuOTk3LTI2LjIwNyA5Ljk5Ny0zNi4yMDQtLjAwMXoiPjwvcGF0aD48L3N2Zz4=);
        opacity: 0;
        transition: opacity .2s linear; }
    .field-container.custom-checkbox input:checked + label::after {
      opacity: 1; }

.annotation {
  font-size: 0.85rem; }
  .annotation.right-side {
    text-align: right; }

@keyframes bounceRight {
  0% {
    transform: translateX(10px); }
  100% {
    transform: translateX(0); } }

@keyframes spin {
  100% {
    transform: rotate(360deg); } }

@keyframes blinkFade {
  100% {
    opacity: .25; } }

.textlink {
  color: #1c85d3;
  text-decoration: none; }
  .textlink:hover {
    background: linear-gradient(135deg, #f39200, #f57800);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text; }

#outdated {
  text-align: center;
  display: inline-block;
  padding: 2rem;
  width: 100%;
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  background-color: black;
  color: white; }
  #outdated .inline {
    display: inline-block; }

.outdated-close {
  border-radius: 5px;
  margin-left: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  background-color: #f39200; }

.animate h2, .animate h3, .animate p, .animate .paragraph-animated {
  opacity: 0; }
  .animate h2.start-animation, .animate h3.start-animation, .animate p.start-animation, .animate .paragraph-animated.start-animation {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-name: fade-in; }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
