$font: 'Rajdhani', sans-serif;
$col-space: 30px;

// COLORS
//$color-cyan: #22E1FF;
$color-cyan: #1c85d3;
$color-cyan-transparent: rgba(28, 133, 211, 0.90);
$color-darkcyan: #3077c9;
$color-darkcyan-transparent: rgba(48, 119, 201, 0.90);
$color-orange: #f39200;
$color-darkorange: #f57800;
$color-dark: #222; 
$color-black: #000; 
$color-light: #CCC;
$color-dwhite: #EEE;
$color-white: #FFF;
$color-footergray: #a7a7a7;



// GRADIENTS
$orange-gradient: linear-gradient(135deg, $color-orange, $color-darkorange);

$black-gradient: linear-gradient(to right, #1d1d1d, #000);
$white-gradient: linear-gradient(to right, #FFFFFF, #C0C0C0);

@mixin linearGradient($direction: 'right', $color1: $color-orange, $color2: $color-darkorange) {
    background: linear-gradient(to $direction, $color1, $color2);
}


// TYPOGRAPHY SIZES
$h1: 3.5rem;
$h2: 3.25rem;
$h3: 2rem;
$h4: 1.625rem;
$p: 1.125rem;


// Stuff
$border-width: 2px;

// Breakpoint Mixins 
@mixin tiny-mobile {
    @media (max-width: 380px) { @content; }
}
@mixin s-mobile {
    @media (max-width: 480px) { @content; }
}
@mixin mobile {
    @media (max-width: 768px) { @content; }
}
@mixin touch {
    @media (max-width: 1023px) { @content; }
}
@mixin max-widescreen {
    @media (max-width: 1215px) { @content; }
}
@mixin tablet {
    @media (min-width: 769px) { @content; }   
}
@mixin desktop {
    @media (min-width: 1024px) { @content; }
}
@mixin widescreen {
    @media (min-width: 1216px) { @content; }
}
@mixin fullhd {
    @media (min-width: 1408px) { @content; }
}
@mixin portrait {
    @media (orientation: portrait) {@content;}
}

// Height-related Breakpoints
@mixin ver-mobile {
    @media (max-height: 768px) { @content; }
}
@mixin ver-tiny-mobile {
    @media (max-height: 380px) { @content; }
}


// Custom Special Breakpoints
@mixin tablet-nav {
    @media (max-width: 864px) and (min-width: 770px) { @content; }
}

@mixin skills-column-break {
    @media (max-width: 950px) { @content; } // this needs to change in skills.js' window.innerHeight check too
}

@mixin footer-two-columns {
    @media (max-width: 650px) { @content; }
}

@mixin team-first-resize {
    @media (max-width: 900px) { @content; }
}

@mixin team-second-resize {
    @media (max-width: 600px) { @content; }
}