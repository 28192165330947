@import '~reset-css/sass/reset';
@import "./variables";

* {
    box-sizing:border-box;
}

html {
  @include mobile {
    font-size:14px;
  }
  font-size:18px;
  font-weight:500;
}

body,
input, textarea, select, button {
    font-family: $font;
}
body {
  background:$color-black;
}


.section {
  padding:80px 0;
}

.inner {
  &.fullpage {
    min-height:100vh;
  }
}

.logo-svg {
    path, polygon {
      fill:url(#whitegradient);
    }

    path#y7Dev-dev{
      fill:url(#bluegradient);
    }

    &.inAnimation {
      path, polygon {  
        &#y7Dev-y7 {
          fill:rgba(255,255,255,0);
          stroke:#FFF;
          stroke-width:1;
          stroke-dasharray: 410;
          stroke-dashoffset: 410;
          animation: drawlogo_stroke 5s linear forwards, drawlogo_fill 2s linear 5s forwards;
        }

        &#y7Dev-dev {
          opacity:0;
          animation:slideIn 2s ease 5s forwards;
        }
        
      }
    }
  }
  
  @keyframes drawlogo_stroke {
    to { 
      stroke-dashoffset: 0;
    }
  }
  @keyframes drawlogo_fill {
    to { 
      fill:rgba(255,255,255,1);
    }
  }
  @keyframes fadeIn {
    from {
      opacity:0;
    }
    to {
      opacity:1;
    }
  }
  @keyframes slideIn {
    from {
      opacity:0;
      transform:translateX(-100%) skewX(-45deg);
    }
    to {
      opacity:1;
      transform:translateX(0) skewX(0deg);
    }
  }


.button {
    font-size:$p;
    border-radius:0;
    padding:0px 14px;
    margin-bottom:15px;
    display:inline-block;
    //border:$border-width solid transparent;
    border:none;
    //border-left:3px solid $color-orange;
    box-sizing:border-box;
    position:relative;
    z-index:120;
    background:transparent;
    cursor:pointer;

    > div {
      padding:5px 15px 5px 20px;
      display:inline-flex;
      justify-content:center;
      flex-direction:row;
      text-decoration:none;
      box-sizing:border-box;
      background:#000;
      width:100%;
      transition: 0.2s ease-in-out;
      @include mobile {
        padding:5px 10px 5px 15px;
      }
    }

    &.expanded {
      width:100%;
      display:block;
    }
  
    &::before {
      content:'';
      display:block;
      position:absolute;
      height:100%;
      width:15px;
      left:0;
      top:0;
      background-image:url('../images/buttons/buttonBefore.svg');
      background-size:100% 100%;
      transition: 0.2s ease-in-out;
    }

    &.whiteArrow::before {
      background-image:url('../images/buttons/buttonBefore_inverted.svg');
    }

    &::after {
      content:'';
      display:block;
      position:absolute;
      height:100%;
      width:15px;
      right:0;
      top:0;
      background-image:url('../images/buttons/buttonAfter.svg');
      background-size:100% 100%;
      transition: 0.2s ease-in-out;
    }
    
    > div > span {
      min-height:36px;
      line-height:36px;
      position:relative;
      z-index:122;
      color:#FFF;
      display:inline-flex;
      transition: 0.2s ease-in-out;

      > svg {
        //height:100%;
        height: 36px; // IE requires fixed height value
      }

      &:not(.icon) {
        font-size:$p;
      }

      &.icon {
        margin-left:20px;
      }
    }

    &:hover {
      /*
      background:none !important;
      -webkit-background-clip:unset !important;
      -webkit-text-fill-color:unset !important;
      background-clip:unset !important;
      */
      > span {
        color:$color-white;
      }

      .icon.bounceRight {
        animation: bounceRight .5s ease alternate-reverse infinite;
      }
      &::before {
        background-image:url('../images/buttons/buttonBeforeHover.svg');
      }
      &::after {
        background-image:url('../images/buttons/buttonAfterHover.svg');
      }
      > div {
        background: #333;
      }
    }

    &.progressing {
      cursor:wait;

      .icon {
        animation:spin 2s linear infinite !important;
      }

      &::after {
        animation:blinkFade .5s ease alternate-reverse infinite;
      }
    }

    &.light {
      &::after {
        background:rgba(255,255,255,.75);
      }
      > span {
        color:$color-black;
      }
      &:hover {
        > span {
          color:$color-black;
        }
      }
    }

}

.multiSelectFilter {
    padding:0 15px;

    &::before {
      content:'';
      display:block;
      position:absolute;
      height:100%;
      width:15px;
      left:0;
      top:0;
      background-image:url('../images/buttons/buttonOutlinedBefore.svg');
      background-size:100% 100%;
    }
    &::after {
      content:'';
      display:block;
      position:absolute;
      height:100%;
      width:15px;
      right:0;
      top:0;
      background-image:url('../images/buttons/buttonOutlinedAfter.svg');
      background-size:100% 100%;
    }

    .arrowSelect__control {
      background:#f39200;
    }

    .arrowSelect__value-container {
      height:44px;
    }
    .arrowSelect__menu {
      left:0;
    }
}




.field-container {
  position: relative;
  margin-bottom:1rem;
  &.shadow {
    box-shadow: 5px 5px 0 0 rgba(0,0,0,0.45);
  }
  textarea.shadow, input.shadow {
    box-shadow: 5px 5px 0 0 rgba(0,0,0,0.45);
    -webkit-appearance: none;
    -webkit-border-radius:0px;
  }
  .contact-form-field {
    border-radius: 0;
    width: auto;
    padding: 0.5rem;
    font-size:16px;
    -webkit-appearance: none;
    -webkit-border-radius:0px;
    transition: border-color .2s linear;

    &.expanded {
      width:100%;
      border: none;
    }
  
    &:focus {
        border-color:#FFF;
    }    
  }
  textarea.contact-form-field {
    min-height:100px;
    resize:vertical;
    overflow-x:hidden;
    overflow-y:auto;
  }

  .invalid-icon {
    position: absolute;
    right: .75rem;
    top: 50%;
    transform:translateY(-50%);
    path {
        fill: red;
    }
  }

  &.custom-checkbox {
    position: relative;
    padding-right:40px;
    padding-top:5px;
    min-height:26px;

    input {
        position: absolute;
        opacity: 0;
    }
    input + label {
        cursor: pointer;
        padding-left:35px;
        display:block;

        &::before {
          content: '';
          position:absolute;
          left:0;
          top:0;
          background: #fff;
          width:20px + 2*$border-width;
          height:20px + 2*$border-width;
          box-shadow: 5px 5px 0 0 rgba(0,0,0,0.45);
        }

        &::after {
            left:2px;
            top:2px;
            width:20px;
            height:20px;
            position: absolute;
            display:inline-block;
            content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik0xNzMuODk4IDQzOS40MDRsLTE2Ni40LTE2Ni40Yy05Ljk5Ny05Ljk5Ny05Ljk5Ny0yNi4yMDYgMC0zNi4yMDRsMzYuMjAzLTM2LjIwNGM5Ljk5Ny05Ljk5OCAyNi4yMDctOS45OTggMzYuMjA0IDBMMTkyIDMxMi42OSA0MzIuMDk1IDcyLjU5NmM5Ljk5Ny05Ljk5NyAyNi4yMDctOS45OTcgMzYuMjA0IDBsMzYuMjAzIDM2LjIwNGM5Ljk5NyA5Ljk5NyA5Ljk5NyAyNi4yMDYgMCAzNi4yMDRsLTI5NC40IDI5NC40MDFjLTkuOTk4IDkuOTk3LTI2LjIwNyA5Ljk5Ny0zNi4yMDQtLjAwMXoiPjwvcGF0aD48L3N2Zz4=);
            opacity:0;
            transition: opacity .2s linear;
        }
    }
    input:checked + label {
        &::after {
            opacity:1;
        }
    }
  }
}


.annotation {
  font-size: 0.85rem;
  &.right-side {
      text-align: right;
  }
}

@keyframes bounceRight {
  0% {
    transform:translateX(10px);
  }
  100% { 
    transform:translateX(0);
  }
}
@keyframes spin {
  100% {transform:rotate(360deg)}
}
@keyframes blinkFade {
  100% {
    opacity:.25;
  }
}

.textlink {
  color:$color-cyan;
  text-decoration:none;

  &:hover {
    background:$orange-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color:transparent;
    background-clip:text;
  }
}

#outdated {
    text-align: center;
    display: inline-block;
    padding: 2rem;
    width: 100%;
    position: fixed;
    z-index: 500;
    top: 0;
    left: 0;
    background-color: black;
    color: white;
    .inline {
        display: inline-block;
    }
}
.outdated-close {
    border-radius: 5px;
    margin-left: 1rem;
    padding: 0.5rem;
    cursor: pointer;
    background-color: $color-orange;
}

// paragraphAnimator
.animate {
    h2, h3, p, .paragraph-animated {
        opacity: 0;
        &.start-animation {
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-timing-function: ease-in-out;
            animation-name: fade-in;
        }
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}