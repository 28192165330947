@import "./variables";
#footer {
    //padding:$col-space 0 0;
    //position:fixed;
    position: relative;
    //z-index:80;
    z-index: 90;
    width:100%;
    bottom:0;
    left:0;
    background: radial-gradient(ellipse at center top, #444 0%,#111 100%);
    color:#FFF;

    h4 {
        font-size:1rem;
        font-weight:200;
        font-family:'Rajdhani';
        height:1.75rem;
        color: $color-footergray;

        span {
            border-bottom:1px solid $color-orange;
        }
    }

    a {
        color:$color-white;
    }

    p {
        font-weight:200;
        line-height:2rem;
    }

    .contactlist .contactitem {
        margin-bottom:0.5rem;
        font-weight:200;
        position:relative;
        padding-left:30px;

        &.without-label {
            padding-left:0;
        }
        
        &:last-child {
            margin-bottom:0;
        }

        .label,
        .item {
            display:inline-block;
        }

        .label {
            margin-right:.5em;
            font-weight:400;
            position: absolute;
            left:0;
            top:0;
        }
        .item{
            font-weight:200;
        }
    }

    #absoluteFooter {
        //border-top:1px solid $color-white;
        font-weight:400;
        background:rgba($color-black, 0.85);
        padding-top:20px;
        padding-bottom:20px;
        //margin-top:20px;
        color: $color-footergray;
        .row {
            justify-content: space-between;
            flex-direction: row;
        }
        .col {
            margin: auto 0;
            display: flex;
            flex-basis: auto;
            flex-grow: auto;
            flex-shrink: auto;
            margin: 0;
            font-size: 18px;
            svg {
                margin-left: 0.3rem;
                margin-right: 0.2rem;
            }
            &.copyright {
                padding-left: 30px;
                padding-right: 10px;
                div {
                    margin-top: auto;
                }
            }
            &.imprint {
                margin-top: auto;
                padding-left: 10px;
                padding-right: 30px;
                justify-content: flex-end;
                margin-bottom: 0;
                a:hover {
                    color: $color-orange;
                }
            }
            .copyright-line {
                padding: 0 0.4rem;
            }
            @include tiny-mobile {
                &.copyright, &.imprint {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
        
        @include touch {
            margin-top: 0;
            .row {
                flex-direction: column;
            }
            .col {
                margin: 0 auto;
                &.imprint, &.copyright {
                    padding-left: 10px;
                    padding-right: 10px;
                }
                &.imprint {
                    margin-top: 10px;
                }
            }
        }
        @media screen and (max-width: 510px) {
            .col {
                font-size: 13px;
                padding-left: 10px;
                padding-right: 10px;
                .copyright-line {
                    display: none;
                }
            }
        }
        @media screen and (max-width: 380px) {
            .col.copyright {
                flex-direction: column;
                text-align: center;
            }
        }
        .imprint {
            text-align:left;
            color:$color-white;
            text-align: right;
            a {
                text-decoration: none;
                color: $color-footergray;
            }
        }
    }
    .footer-inner {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        .row {
            justify-content: space-between;
        }
        .col {
            flex-basis: auto;
            flex-grow: 0;
        }
        @include touch {
            .row {
                flex-direction: row;
                flex-wrap: wrap;
            }
            .col {
                padding-left: 10px;
                padding-right: 10px;
                margin-top: 0;
                &:first-child {
                    padding-left: 30px;
                }
                &:last-child {
                    padding-right: 30px;
                }
            }
        }
        @include mobile {
            .footer-logo-container .logoSvg {
                height: 77%;
            }
        }
        @include footer-two-columns {
            .col {
                flex-basis: 50%;
                flex-grow: 1;
                padding-left: 30px;
                padding-right: 30px;
                font-size: 1.4rem;
                margin-bottom: 30px;
                &:nth-child(odd) {
                    padding-right: 10px;
                }
                &:nth-child(even) {
                    padding-left: 10px;
                }
            }
            h4 {
                font-size: 1.25rem;
                margin-bottom: 0.3rem;
            }
            .footer-logo-container {
                padding-bottom: 1px;
                .logoSvg {
                    height: 90%;
                }
            }
        }
        @include tiny-mobile {
            .col {
                flex-basis: 100%;
                &:nth-child(odd) {
                    padding-right: 30px;
                }
                &:nth-child(even) {
                    padding-left: 30px;
                }
                &:not(.after-logo) {
                    margin-top: 10px;
                }
                &.footer-logo-container {
                    margin-top: 0;
                }
            }
        }
    }
    .footer-logo-container {
        height: 114px;
        padding-top: 4px;
        padding-bottom: 2px;
        .logoSvg {
            height: 100%;
            @include mobile {
                height: 80%;
            }
        }
    }
}