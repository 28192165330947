@import "./variables";

#logo {
    svg {
        overflow: visible;
    }
}
.logoSvg.animation {
    #logo-y {
        transform:translate(-500%, 0%);
        animation-name: logo-y;
        animation-delay:0;
        animation-fill-mode:forwards;
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(0.18,0.73,0.87,0.24);
        animation-duration: 1.5s;
    }
    #logo-7 {
        transform:translate(500%, 0%);
        animation-name: logo-7;
        animation-delay:0;
        animation-fill-mode:forwards;
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(0.18,0.73,0.87,0.24);
        animation-duration: 1.5s;
    }
    #logo-y7 {
        opacity:0;
        animation-name: fadeIn;
        animation-delay:0s;
        animation-fill-mode:forwards;
        animation-iteration-count: 1;
        animation-duration: 1s;
    }
    #logo-web {
        opacity:0;
        animation-name: fadeIn;
        animation-delay:2s;
        animation-fill-mode:forwards;
        animation-iteration-count: 1;
        animation-duration: 0.5s;
    }
    #logo-slogan {
        transform:translate(500%, 0%);
        opacity:1;
        
        animation-name: logo-slogan;
        animation-delay:2s;
        animation-fill-mode:forwards;
        animation-iteration-count: 1;
        animation-timing-function: ease;
        animation-duration: 1s;
    }
    #logo-shine {
        transform:translateY(-50%), rotate(-25deg);
    
        animation-name: logo-shine;
        animation-delay:2s;
        //animation-fill-mode:forwards;
        animation-iteration-count: infinite;
        animation-timing-function: ease;
        animation-duration: 5s;
    }
}

@keyframes logo-y {
    0% {
        transform:translate(-500%, 0%);
    }
    70% {
        transform:translate(15%, 0%);
    }
    85% {
        transform:translate(-5%, 0%);
    }
    100% {
        transform:translate(0%, 0%);
    }
}

@keyframes logo-7 {
    0% {
        transform:translate(500%, 0%);
    }
    70% {
        transform:translate(-15%, 0%);
    }
    85% {
        transform:translate(5%, 0%);
    }
    100% {
        transform:translate(0%, 0%);
    }
}

@keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@keyframes logo-slogan {
    0% {
        opacity:0;
        transform:translate(500%, 0%);
    }
    100% {
        opacity:1;
        transform:translate(0%, 0%);
    }
}

@keyframes logo-shine {
    0% {
        transform:translateY(-50%) rotate(-25deg);
    }
    50% {
        transform:translateY(200%) rotate(-25deg);
    }
    100% {
        transform:translateY(200%) rotate(-25deg);
    }
}