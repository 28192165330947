@import "./variables";
$navElements: 6;

/*
#navLink_home > a {
    filter:drop-shadow(0 0 5px rgba(0,0,0,.35));
}
*/
  
$cloud-width: 220px;

#mainNav {
    position:fixed;
    z-index:100;
    top:0;
    left:0;
    width:100%;
    padding-top:10px;
    max-width:100vw;
    @include linearGradient(top, $color-cyan-transparent, $color-darkcyan-transparent);
    box-shadow:0 5px 0 0 rgba(0,0,0,.45);

    transition:all .5s ease;
    transition: width 0s;
    &.is-mobile {
        transition: 0.2s ease-in-out;
        transition-delay: 0.2s;
        height: 62px;
        ul {
            position: absolute;
            top: 0;
            left: 0;
            background: rgba($color-cyan, 1);
            opacity: 0;
            transition: 0.3s ease-in-out;
            transition-delay: 0s;
        }
        &.nightmode ul {
            background: linear-gradient(to top, rgba($color-dark, 1), rgba($color-black, 1));
        }
        .mobile-page-title-container {
            transform: translateY(0px);
            transition: 0.5s ease-in-out;
            transition-delay: 0.3s;
        }
        .homelink {
            transition: 0.2s ease-in-out;
            transition-delay: 0.4s;
            transform: translateX(0px);
        }
        #mobileNavTrigger {
            z-index: 104;
            position: static;
            justify-content: flex-start;
            &::before { // increases touch area to prevent accidental clicks on first nav link
                content: '';
                position: absolute;
                height: 60px;
                width: 60px;
                right: 0;
                top: 0;
            }
            svg {
                position: absolute;
                right: 20px;
                top: 15px;
            }
        }
        .page-title {
            font-weight: bold;
            justify-content: flex-start;
        }
        &.is-active {
            height: 236px;
            transition-delay: 0s;
            .mobile-page-title-container {
                align-items: flex-start;
            }
            ul {
                z-index: 103;
                opacity: 1;
                transition-delay: 0.2s;
            }
            .mobile-page-title-container {
                transition-delay: 0s;
                transform: translateY(-200px);
            }
            .homelink {
                transform: translateX(-200px);
                transition-delay: 0.2s;
            }
            #mobileNavTrigger {
                align-items: flex-start;
            }
        }
    }
    &.homepage {
        transition: 0.2s ease-in-out;
        background: linear-gradient(to top, rgba($color-cyan, 0), rgba($color-darkcyan, 0));
        box-shadow:none;
        &.is-mobile {
            ul {
                transition: 0.4s linear;
                top: -410%;
                transition-delay: 0.2s;
                li {
                    opacity: 0;
                    transition: 0.2s ease-in-out;
                }
            }
            &.is-active {
                ul {
                    box-shadow:0 5px 0 0 rgba(0,0,0,.45);
                    top: 0;
                    transition-delay: 0s;
                    li {
                        opacity: 1;
                        transition-delay: 0.5s;
                    }
                }
            }
        }
        > ul > li#navLink_home,
        .homelink {
            opacity:0;
            pointer-events:none;
            left: -100%;
            a {
                //top:-110px;
                top: -5px;
            }
            
        }
        > ul {
            > li {
                &:not(#navLink_home) {
                    a {
                        background:transparent;
                    }
                }
                &.main-item .submenu {
                    background: transparent ;
                    border-top:5px solid rgba(0,0,0,0);
                }
            }

            @include touch {
                justify-content: center;
            }
        }

        &.nightmode {
            background: linear-gradient(to top, rgba($color-dark, 0), rgba($color-black, 0));
            a:hover {
                > span {
                    color:$color-orange;
                }
            }
        }
    }

    &.nightmode {
        background: linear-gradient(to top, rgba($color-dark, 0.9), rgba($color-black, 0.9));

        > ul > li {
            a.active,
            a:hover {
                > span {
                    color:$color-orange;
                }
            }
        } 

        &:not(.homepage) > ul > li.main-item .submenu {
            background:$color-dark;
        }
    }

    &:not(.homepage) {
        > ul {
            //box-shadow:0 5px 0 0 rgba(0,0,0,.45);

            @include desktop {
                ul.submenu {
                    box-shadow:5px 5px 0 0 rgba(0,0,0,.45);
                    //left:5px;
                }
            }
        }
    }

    @include mobile {
        min-height: 55px;
        padding-top:20px;
        padding-bottom:14px;
        padding-left: 60px;
        display:flex;
        justify-content:space-between;

        > a {
            display:inline-block;
            position: absolute;
            top: 0;
            left: 0;

            #logoCloud {
                position: absolute;
                top: 5px;
                left: -20px;
                max-width: 180px;
            }
            .logoSvg {
                position: absolute;
                top: 35px;
                left: 15px;
                z-index: 102;
            }
        }
        .mobile-page-title-container {
            margin: 0 auto;
        }
    }
    @include s-mobile {
        .mobile-page-title-container {
            .page-title {
                font-size: 1.2rem;
            }
        }
    }

    #logoCloud {
        filter:drop-shadow(5px 5px 0 rgba(0,0,0,.35));
        z-index:101;
    }

    #mobileNavTrigger {
        position:relative;
        display:inline-flex;
        align-items:center;
        color:$color-white;
        cursor:pointer;

        > span {
            margin-right:10px;
        }
    }

    &.initialized {
        > ul > li {
            opacity:1;

            &:not(#navLink_home) > a {
                top:0;
            }
        }
    }

    > ul {
        position:relative;
        z-index:101;
        max-width:1400px;
        margin:0 auto;
        display:flex;
        //justify-content:flex-start;
        justify-content:center;
        align-items:stretch;
        align-content:stretch;
        list-style:none;
        padding:0;
        //padding-left:$cloud-width;

        @include touch {
            justify-content: flex-end;
        }
        @include mobile {
            display:block;
            pointer-events:none;
            overflow-x:hidden;
            overflow-y:auto;
            position:absolute;
            width:100%;
            left:0;
            top: calc(100% + 4px); // 4px to display header shadow on active menu
            background: rgba($color-cyan, 0.95);
            opacity:0;
            transition:opacity .2s ease;

            max-height:calc(100vh - 100% - 4px);

            &.active {
                pointer-events:all;
            }
        }

        > li {
            //flex-grow:1;
            text-align:center;
            position:relative;
            opacity:0;
            @include tablet {
                display:flex;
            }
            justify-content:center;
            flex-direction:column;
            z-index:102;
            //transition:opacity .2s linear;

            &:not(#navLink_home) {
                @include desktop {
                    margin:0 10px;   
                }

                > a {
                    position:relative;
                    top:-65px;
                    transition:top .5s ease;
                }
            }

            &#navLink_home {
                width:$cloud-width;
                position:absolute;
                left:0;
                opacity:1;
                pointer-events:all;
                transition:opacity .5s ease;
                z-index:103;

                
                .logoSvg {
                    position:absolute;
                    z-index:102;
                    left:50%;
                    top:50%;
                    transform:translate(-50%, -35%);
                    width:58%;
                }

                #logoCloud {
                    position:relative;
                }
            }

            @include mobile {
                opacity:1;
                flex-grow:0;
            }

            
            @for $i from 0 to $navElements {
                &:nth-child(#{$i + 1}) {
                    //transition:opacity .2s linear #{$i * 0.3}s;
                }
            }
            
            a {
                text-decoration:none;
                display:block;
                font-size:24px;
                padding:20px 5px 15px 5px;
                margin:0 20px;
                position:relative;
                z-index:10;
                font-family:'Rajdhani';
                font-weight:600;
                cursor:pointer;
                z-index:102;

                &:not(.homelink) {
                    //background:$color-cyan;
                    padding-left:15px;
                    padding-right:15px;
                    margin:0 0;
                }

                &.homelink {
                    padding:0;
                    top:-5px;
                    left: 0;
                    transition:all 0.5s ease;
                }

                > span {
                    position:relative;
                    color:#FFF;
                    //text-shadow:0 1px 1px rgba(0,0,0,.5);
                    display:inline-block;

                    > span {
                        
                        @include tablet {
                            display:block;
                        }
                        @include widescreen {
                            display:inline-block;
                        }
                        @include tablet-nav {
                            font-size: 1.1rem;
                        }
                    }

                    /*
                    &::after {
                        content:'';
                        background:$orange-gradient;
                        height:1px;
                        position:absolute;
                        width:0;
                        bottom:-5px;
                        left:50%;
                        margin-left:0;
                        opacity:0;
                        transition:all .5s ease;
                    }
                    */

                    span.icon {
                        margin-right:10px;
                        svg path {
                            fill:url(#whitegradient);
                        }
                    }
                }

                &:hover > span,
                &.active > span {
                    color:$color-black;
                    /*
                    &::after {
                        width:60%;
                        margin-left:calc(-60% / 2);
                        opacity:1;
                    }
                    */
                }
                &:hover,
                &.active {
                    span.icon svg path {
                        fill: url(#orangegradient);
                    }
                }
            }
            &.main-item {
                .submenu-expander {
                    position: absolute;
                    z-index: 102;
                    cursor: pointer;
                    left:50%;
                    transform:translateX(-50%);
                    bottom:0;
                    align-self: center;
                    color: #fff;
                }
                .submenu {
                    /*padding:0 15px 0 15px;*/
                    width:100%;
                    position: absolute;
                    z-index:101;
                    align-self: center;
                    top: 0;
                    background: rgba($color-cyan, 1);
                    opacity: 0;
                    pointer-events: none;
                    transition: 0.2s ease-in-out;

                    border-top:5px solid rgba(0,0,0,0.5);

                    a {
                        font-weight:500;
                        font-size:18px;
                        text-align:left;
                        padding: 10px 15px;
                    }
                }
                @include tablet {
                    &:hover {
                        .submenu {
                            top:100%;
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }
                @include mobile {
                    .submenu-expander {
                        padding: 15px;
                        align-self: top;
                        top: 10px;
                        left:auto;
                        right:0;
                        width:50px;
                        transform:none;
                    }
                    .submenu {
                        background: rgba(0, 0, 0, 0.5);
                        width: 100%;
                        &.is-active {
                            position: static;
                            opacity: 1;
                            pointer-events: all;
                            background: rgba(0,0,0,0.5);
                        }
                    }
                    > a {
                        margin: 0;
                        &.is-active {
                            box-shadow: 0 5px 0 0 rgba(0,0,0,0.5);
                        }
                    }
                }
            }
        }
    }
}
#mobileNavOutside {
    display:none;
    opacity:0;

    background:rgba(0,0,0,.35);
    position:fixed;
    top:0;
    left:0;
    height:100vh;
    width:100vw;
    z-index:99;
    transition: opacity 0.2s ease;

    &.active {
        display:block;
        opacity:1;
    }
}

.mobile-page-title-container {
    display: inline-flex;
    align-items: center;
    .page-title, .icon {
        font-size: 1.5rem;
    }
    .icon {
        margin-right: 10px;
        path {
            fill: url(#orangegradient);
        }
    }
    .page-title {
        color: white;
    }
}


@keyframes erase {
  from { stroke-dashoffset: 0; }
  to { stroke-dashoffset: -2120; }
}
@keyframes fill {
    to{
        fill-opacity:1;
    }
}
@keyframes unfill {
    from {
        fill-opacity:1;
    }
    to {
        fill-opacity:0;
    }
}


// Responsive Nav Menu Trigger
$spanPosition: 9px;
#nav-icon {
    width: 25px;
    height: 22px;
    position: absolute;
    top: 20px;
    right: 20px;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
}
  
#nav-icon span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: white;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}


#nav-icon span:nth-child(1) {
    top: 0px;
}

#nav-icon span:nth-child(2),#nav-icon span:nth-child(3) {
    top: $spanPosition;
}

#nav-icon span:nth-child(4) {
    top: $spanPosition * 2;
}

#nav-icon.open span:nth-child(1) {
    top: $spanPosition;
    width: 0%;
    left: 50%;
}

#nav-icon.open span:nth-child(2) {
    transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
    transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(4) {
    top: $spanPosition;
    width: 0%;
    left: 50%;
}