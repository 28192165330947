@import "./variables";

#App {
  background:$black-gradient;
  position:relative;
}

.is-color-black {
    color: #000;
}

.bgVideo-container {
  position:absolute;
  width:100%;
  height:100%; 
  overflow:hidden;
  background: linear-gradient(135deg, #878787 0%,#222 100%);

  &.darken::after {
    content:'';
    position:absolute;
    width:100%;
    height:100%;
    background:rgba(0,0,0,.5);
  }
}
.bgVideo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform:translate(-50%, -50%);
    min-width: 100%; 
    min-height: 100%;
}

#content {
  color: #f1f1f1;
  /*
  background:#000;
  width: 100%;
  height:100%;
  */
  position:relative;
  z-index:90;
  pointer-events:none;
  min-height:100vh;
    &.behind-footer {
        z-index: 79;
    }
  .inner {
    //position:absolute;
    pointer-events:all;
    position:relative;
    min-height:100%;
    width:100%;
    display:flex;
    justify-content:flex-start;
    flex-direction:column;
    box-sizing:border-box;
    padding-top:78px;
    padding-bottom:$col-space;
    min-height:calc(100vh + 1px);
    overflow:hidden;
    //background:$black-gradient;
    //border-bottom:1px solid $color-black;
    //box-shadow:0 5px 5px 0px rgba(0,0,0,.45);

    &.sectioned {
      padding-top:68px;
      padding-bottom:0;

       section {
        padding:5rem 0;
        min-height:calc(100vh - 68px);
        position:relative;
        overflow:hidden;

         .corner {
            position:absolute;
            top:-1px;
            bottom:auto;

            &.right {
                right:-1px;
                height: 100%; // IE fix
                width: 60vh;

                @include touch {
                 visibility: hidden;
                }
            }

            &.bottom {
                display:block;
                right:-1px;
                bottom:-1px;
                height:auto;
                width: 60vh;
                transform:scaleY(-1);

                @include touch {
                    width: 40vw;
                }
                @include mobile {
                    visibility: hidden;
                }
            }

            &.bottom2 {
              display:block;
              right:-1px;
              bottom:-1px;
              height:auto;
              width: 45vh;
              transform:scaleY(-1);

              @include touch {
                  width: 30vw;
              }
              @include mobile {
                  visibility: hidden;
              }
            }
        }
        
        .sectionDescription {
          margin:3rem 0;

          @include mobile {
            margin:2rem 0;
          }
        }

        
        &.dark {
          /*background:$color-dark;*/
          background: linear-gradient(135deg, #878787 0%,#222 100%);

          h2 {
            color:$color-orange;
          }
        }
        &.light {
          background:linear-gradient(135deg, #ffffff, #DDD, #AAA);
          color:$color-dark;

          h2 {
            color:$color-orange;
          }
        }
        &.orange {
          background: linear-gradient(135deg, #ffb45a, $color-orange, #db6300);
          color:$color-dark;

          h2 {
            color:$color-white;
          }

          .button::before {
            background-image:url('../images/buttons/buttonBefore_inverted.svg');
          }
        }
        &.no-background {
            background: transparent;
        }
        
      }
      // Individual Skill styles
      > section#webservice {
        > .container {
            overflow: visible;
        }
        .imgcol img {
            transform: translate(-20px, -50px);
        }
        @include touch {
            .imgcol img {
                transform: translate(0px, 0px);
            }
        }
      }

    }

    &.darkened {
      //background: rgba(0, 0, 0, 0.75);
    }

    &.va-center {
      align-items:center;
      justify-content:center;
    }
  }

  > div {
    width:100%;
    min-height:100vh;
    top:0;
    left:0;
    opacity:0;
    animation:fadeInContent 0.75s linear 0s 1 forwards;

    &.fa-exit {
      opacity:1;
      animation:none;
      position: absolute !important;
    }
  }
}

@keyframes fadeInContent {
  0% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
}

.container {
    max-width: 1400px;
    width:100%;
    margin:0 auto;
    overflow:hidden;

  &.orangebg {
    background:$orange-gradient;
  }

  &:not(.fluid) {
    padding-left:$col-space;
    padding-right:$col-space;
  }

  &.noTitleMargin {
    padding-top:60px;
  }

  @include desktop {
    //max-width:1400px;
    padding-left:0;
    padding-right:0;
  }
  @include widescreen {
    //max-width:1200px;
  }
  @include fullhd {
    //max-width:1400px;
  }

  &.flex-grow {
    display:flex;
    flex-grow:1;
    justify-items:stretch;
    overflow:visible;

    > div {
      flex-grow:1;
    }
  }

  &.fluid {
    max-width:none;
    width:100%;

    > .row {
      margin-left:0;
      margin-right:0;
    }
  }
  &.header {
    //margin-bottom:40px;
  }

}
.row {
  position:relative;
  margin-left:-$col-space;
  margin-right:-$col-space;
  display:flex;
  flex-direction:column;
  
  @include desktop {
    flex-direction:row;
  }
}
.col {
  padding-left:$col-space;
  padding-right:$col-space;
  flex-basis:0;
  flex-grow:1;
  flex-shrink:1;

  &.valign {
    display:block;

    @include desktop {
      display:flex;
    }
    
    &.imgcol {
      text-align:center;
    }

    > div {
      align-self:center;
      width:100%;

      > img {
        width:100%;
        max-width:640px;
        //height:auto;
        height: 100%; //IE fix
      }
    }
  }

  & + .col {
    margin-top:$col-space;
  }

  @include desktop {
    & + .col {
      margin-top:0;
    }
  }
}

.col-sidebar {
  width:25%;
  flex-grow:0;
  flex-shrink:0;
  position:relative;
  /*border-left:1px solid #FFF;*/

  .col-sidebar-fixed {
    position:fixed;
    //width:100%;  width 100% with fixed doesnt work, needs 
  }

  .sidebar-box {
    margin-top:60px;
  }
}


/* TYPO */


.header-row {
  overflow:hidden;
  > .col {
    display:flex;
    width:100%;
    height:148px;
    justify-content:center;
    align-items:center;
    flex-basis:1;
    .content h1 {
      margin: 20px 0 0 0;
    }
  }
}


.content {
  font-size:$p;
  p {
      margin-bottom:20px;

      & + h2 {
        margin-top:60px;
      }
  }
  h1 {
    font-size:$h1;
    margin: 60px 0 40px;
    text-align:center;
    font-family:'Rajdhani';
    font-weight:700;

    @include mobile {
      font-size:#{$h1 - 1rem};
    }
  }
  h2 {
    font-size:$h2;
    margin:0 0 40px 0;
    font-family:'Rajdhani';
    font-weight:700;

    & + h3 {
      margin-top:-40px;
    }

    @include mobile {
      font-size:#{$h2 - 1.25rem};
    }
  }
  h3 {
    font-size:$h3;
    font-weight:300;

    @include mobile {
      font-size:#{$h3 - .5rem};
    }
  }
  a {
    color:$color-orange;
    text-decoration:none;
    cursor: pointer;

    &:not(.button):hover {
      background:$orange-gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color:transparent;
      background-clip:text;
    }
  }
  strong {
    font-weight:600;
  }

  ul, ol {
    padding-left:$col-space;

    li {
      position:relative;
      margin-top:5px;
      margin-bottom:5px;

      &::before {
        content:'';
        position:absolute;
        left:calc(-#{$col-space} / 2);
        top:2px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 10px;
        border-color: transparent transparent transparent $color-orange;
      }
    }
  }
}

.arrow-indicator {
    position: fixed;
    bottom: 0;
    left: 0;
    text-align: center;
    width: 100%;
    animation:bounceY 1s ease-in-out infinite;
}

@keyframes bounceY {
    0%, 100% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, -2px);
    }
}